<template>
  <main v-if="activity" class="activity-page page">
    <BreadCrumbsComponent :title="activity.title" />
    <div class="container-padding">
      <div v-if="activity" class="activity-page__inner">
        <EditorJSComponent :text="activity?.description" />
        <DocumentListComponent
          v-if="activity.files && activity.files.length"
          :documents="activity.files"
          activity
        />
        <div class="activity-page__activities">
          <ActivityComponent v-for="(item, i) in activity.child_activities" :key="i" :activity="item" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BreadCrumbsComponent from "components/BreadcrumbsComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import DocumentListComponent from "../documents/components/DocumentListComponent.vue";
import ACTIVITY_PAGE from "gql/pages/ActivityPage.graphql";
import ActivityComponent from "components/ActivityComponent.vue";

export default {
  name: "ActivityPage",
  async asyncData({ route, apollo, store }) {
    await apollo.defaultClient
      .query({
        query: ACTIVITY_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        store.dispatch("activity/save", data);
      })
      .catch(() => {});
  },
  computed: {
    activity() {
      return this.$store.state.activity.activities_item;
    },
  },
  components: {
    ActivityComponent,
    DocumentListComponent,
    EditorJSComponent,
    BreadCrumbsComponent,
  },
};
</script>

<style lang="stylus">
.activity-page {

  &__inner {
    display grid
    grid-gap 30px
  }

  &__activities {
    margin-top 20px
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 40px
    width 100%
    +below(900px) {
      grid-template-columns 1fr
      gap 20px
    }
  }
}
</style>
