<template>
  <div v-if="activity" class="activity-component">
    <!--    <div class="activity-component__header">-->
    <!--      <span v-if="document.published_at" class="activity-component__date">-->
    <!--        {{ document.published_at | formatDate }}-->
    <!--      </span>-->
    <!--      <span v-if="document['document_type']" class="activity-component__category">-->
    <!--        {{ document["document_type"].title }}-->
    <!--      </span>-->
    <!--    </div>-->
    <p v-if="activity.title" class="activity-component__title">{{ activity.title }}</p>
    <router-link :to="{ name: 'activity', params: { id: activity.id } }" class="activity-component__btn">
      <span>Перейти</span>
      <IconComponent name="arrow-right" />
    </router-link>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "ActivityComponent",
  props: {
    activity: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
.activity-component {
  display flex
  flex-direction column
  gap 15px
  padding 30px
  border 1px solid var(--gray-dark)
  background url("/static/svg/coat-of-arms.svg")
  background-position 95% 20%
  background-repeat no-repeat
  background-color var(--white)

  &__header {
    display flex
    align-items center
    gap 15px
  }

  &__date {
    font-size .875rem
    font-weight 700
    line-height 22px
    color var(--main)
    padding 5px 10px
    background var(--white)
  }

  &__category {
    font-size .875rem
    line-height 22px
    color var(--main_o5)
    padding 5px 10px
    background var(--white)
  }

  &__btn {
    display flex
    align-items center
    gap 14px

    span {
      font-size .875rem
      line-height 22px
    }

    .icon svg {
      max-width 16px
      max-height 16px
    }
  }
}
</style>
